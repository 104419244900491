// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

td {
  vertical-align: middle !important;
}

.page-link {
  color: #2f353a;
}
.page-item.active .page-link {
  background-color: #2f353a !important;
  border-color: #000000 !important;
}

.wrapper-class {
  border: 1px solid #dddddd;
}

.rdw-link-modal-target-option {
  display: none;
}

.rdw-link-modal-btn {
  width: 80px !important;
}

.card-scroll {
  max-height: 400px !important;
  overflow-y: scroll !important;
}
